import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoPopup from "../../../components/VideoPopup/VideoPopup";
import useGlobalContext from "../../../hooks/useGlobalContext";
import axios from "axios";
import countryCodes from "../../../constants/countrycodes.json";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import Swal from "sweetalert2";

const HomeTwoHeroSection = () => {
  const query = new URLSearchParams(window.location.search);

  const values = useGlobalContext();
  const [state, setState] = useState({
    first_name: "",
    mobileno: "",
    gender: "male",
    age: "",
    country_code: "+91",
    visible: false,
    utm_source: query.get("utm_source"),
    utm_campaign: query.get("utm_campaign"),
  });
  const [apiResponse, setResponse] = useState({
    error: false,
    message: "",
    loading: false,
  });
  const [timeLeft, setTimeLeft] = useState(20);

  const { setIsOpen } = values;

  const handleOptionChange = (event) => {
    //setGender(event.target.value);
    setState({ ...state, gender: event.target.value });
  };

  const handleSubmit = () => {
    if (!state.first_name) {
      Swal.fire({
        title: "Name is missing",
      });
    } else if (!state.age) {
      Swal.fire({
        title: "Age is missing",
      });
    } else if (state.age > 100) {
      Swal.fire({
        title: "Invalid Age",
      });
    } else if (!state.gender) {
      Swal.fire({
        title: "Gender is missing",
      });
    } else if (!state.mobileno) {
      Swal.fire({
        title: "Mobile No is missing",
      });
    } else {
      setState({ ...state, visible: true });
      startTimer();
      sendOTP();
      //saveData();
    }
  };

  const saveData = () => {
    setResponse({ ...state, loading: true });
    let config = {
      method: "post",
      url: "/dch/add-guest",
      data: { ...state },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResponse({
          ...apiResponse,
          message: response.data.message,
          error: false,
          loading: false,
        });
        setState({
          ...state,
          success_modal: false,
          visible: false,
          first_name: "",
          mobileno: "",
          age: "",
          gender: "male",
        });
        Swal.fire({
          title: response.data.message,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
        setResponse({
          ...apiResponse,
          message: error.message,
          error: true,
          loading: false,
        });
      });
  };

  const startTimer = () => {
    setTimeLeft(20); // Reset the timer to 30 seconds
  };
  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);
  useEffect(() => {
    if (!query.get("utm_source")) {
      Swal.fire({
        title: "Invalid Source",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    } else if (!query.get("utm_campaign")) {
      Swal.fire({
        title: "Invalid Campaign",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    }
  }, []);

  const sendOTP = () => {
    setResponse({ ...state, message: "", error: false });

    let config = {
      method: "post",
      url: "/dch/send-otp",
      data: {
        mobileno: state.mobileno,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResponse({
          ...state,
          message: response.data.message,
          error: !Boolean(response.data.result),
        });
      })
      .catch((error) => {
        console.log(error);
        setResponse({ ...state, message: error.message, error: true });
      });
  };
  const verifyOTP = () => {
    setResponse({ ...state, message: "", error: false });

    let config = {
      method: "post",
      url: "/dch/verify-otp",
      data: {
        mobileno: state.mobileno,
        otp: state.otp,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResponse({
          ...state,
          message: response.data.message,
          error: !Boolean(response.data.result),
        });
        if (Boolean(response.data.result)) {
          saveData();
        }
      })
      .catch((error) => {
        console.log(error);
        setResponse({ ...state, message: error.message, error: true });
      });
  };
  const resendOTP = () => {
    setResponse({ ...state, message: "", error: false });
    startTimer();

    let config = {
      method: "post",
      url: "/dch/resend-otp",
      data: {
        mobileno: state.mobileno,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResponse({
          ...state,
          message: response.data.message,
          error: !Boolean(response.data.result),
        });
      })
      .catch((error) => {
        console.log(error);
        setTimeLeft(0);
        setResponse({ ...state, message: error.message, error: true });
      });
  };
  return (
    <>
      <VideoPopup videoId="oU_GUAWz52w" />
      <Modal
        key={"otp"}
        show={state.visible}
        className="p-2"
        style={{
          zIndex: 9999,
        }}
      >
        <Modal.Header>
          <Modal.Title className="text-center">Verify Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            <div className="col-lg-12 text-center">
              <input
                value={state.otp}
                onChange={(e) => setState({ ...state, otp: e.target.value })}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                minLength={4}
                maxLength={4}
                className="w-90 form-control"
                placeholder="OTP"
              />
            </div>
          </div>
          <span
            key={timeLeft}
            className={
              apiResponse.error == true ? "text-danger" : "text-success"
            }
          >
            {apiResponse.message}
          </span>
        </Modal.Body>
        <Modal.Footer>
          {timeLeft > 0 ? (
            <span
              className="text-success"
              onClick={() => {
                resendOTP();
              }}
            >
              {timeLeft} Sec
            </span>
          ) : (
            <button
              className="btn btn-info btn-sm text-white"
              onClick={() => {
                resendOTP();
              }}
            >
              Resend
            </button>
          )}
          <button
            className="btn btn-success btn-sm "
            onClick={() => {
              verifyOTP();
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      <section className="hero-area">
        <div className="hero-slider hero_two_slider">
          <div className="slider-active">
            <div
              className="single-slider slider-height slider-height-2 d-flex align-items-center"
              data-background="img/slider/slider-bg-2.jpg"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-6 col-md-10">
                    <p className="text-center">
                      <div className="logo pos-rel">
                        <img src="img/logo/idea-white-logo.png" alt="" />
                      </div>
                    </p>
                    <div className="hero-text hero-text-2 pt-0">
                      <div className="hero-slider-caption hero-slider-caption-2">
                        <h5 className="white-color">
                          We are here for your care.
                        </h5>
                        <h1 className="white-color">
                          {/* Best Care & Better Doctor. */}
                          Get a Diabetes Expert Consultation for{" "}
                          <span>Free</span>
                        </h1>
                        <h2 className="white-color">
                          Get a Free e-book on Diabetes
                        </h2>
                      </div>
                      {/* <div className="hero-slider-btn">
                        <Link
                          to="/about"
                          className="primary_btn btn-icon btn-icon-blue ml-0 d-none"
                        >
                          <span>+</span>about us
                        </Link>
                        <button
                          onClick={() => setIsOpen(true)}
                          className="play-btn popup-video"
                        >
                          <i className="fas fa-play"></i>
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-12">
                    <div className="slider-right-2">
                      <div className="caregive-box">
                        <div className="search-form">
                          {/* <span className="sub-heading">
                            We are here for you
                          </span> */}
                          <h3>
                            Fill in your Details to Avail Free Consultation
                          </h3>
                          {/* <h3>Find A Care Giver</h3> */}
                        </div>
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="appoinment-form-box appoinment-form-box-option d-flex mb-40">
                              {/* <div className="appoint-ment-icon">
                                <img
                                  src="img/icon/caregive-option-icon-2.png"
                                  alt=""
                                />
                              </div> */}
                              <form className="appointment-form-2">
                                <label htmlFor="input">Name</label>
                                <input
                                  required
                                  type="text"
                                  name="name"
                                  autoComplete="off"
                                  value={state.first_name}
                                  onChange={(e) => {
                                    //setName(e.target.value);
                                    setState({
                                      ...state,
                                      first_name: e.target.value,
                                    });
                                  }}
                                />

                                <label htmlFor="input">Age (Yrs)</label>
                                <input
                                  required
                                  min={1}
                                  max={100}
                                  maxLength={3}
                                  type="number"
                                  name="age"
                                  autoComplete="off"
                                  value={state.age}
                                  onChange={(e) => {
                                    setState({ ...state, age: e.target.value });
                                  }}
                                />

                                <div
                                  className="d-flex"
                                  style={{
                                    gap: "1.5rem",
                                  }}
                                >
                                  <label htmlFor="input">Gender</label>
                                  <select
                                    className=""
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        gender: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="Male" key="Male">
                                      Male
                                    </option>
                                    <option value="Female" key="Female">
                                      Female
                                    </option>
                                    <option value="Other" key="Other">
                                      Other
                                    </option>
                                  </select>
                                </div>
                                {/* <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className="d-flex"
                                    style={{
                                      gap: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      value="male"
                                      checked={state.gender === "male"}
                                      onChange={handleOptionChange}
                                    />
                                    <label
                                      for="male"
                                      style={{
                                        marginBottom: "12px",
                                      }}
                                    >
                                      Male
                                    </label>
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{
                                      gap: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="female"
                                      name="gender"
                                      value="female"
                                      checked={state.gender === "female"}
                                      onChange={handleOptionChange}
                                    />
                                    <label
                                      for="female"
                                      style={{
                                        marginBottom: "12px",
                                      }}
                                    >
                                      Female
                                    </label>
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{
                                      gap: "10px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="other"
                                      name="gender"
                                      value="other"
                                      checked={state.gender === "other"}
                                      onChange={handleOptionChange}
                                    />
                                    <label
                                      for="other"
                                      style={{
                                        marginBottom: "12px",
                                      }}
                                    >
                                      Other
                                    </label>
                                  </div>
                                </div> */}
                                <label htmlFor="input">Mobile Number</label>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <select
                                    key="country_code"
                                    name="country_code"
                                    autoComplete="off"
                                    value={state.country_code}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        country_code: e.target.value,
                                      });
                                    }}
                                  >
                                    {countryCodes.map((x) => {
                                      return (
                                        <option value={x.value} key={x.value}>
                                          {x.value}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <input
                                    required
                                    maxLength={10}
                                    type="number"
                                    name="mobileno"
                                    autoComplete="off"
                                    value={state.mobileno}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      //setMobileno(e.target.value);
                                      setState({
                                        ...state,
                                        mobileno: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="col-xl-12 mb-35">
                            <div className="inner caregive-btn text-center">
                              <button
                                disabled={apiResponse.loading}
                                type="submit"
                                className="primary_btn gray-btn-border"
                                onClick={handleSubmit}
                              >
                                Make Appointment
                              </button>
                              {/* <Link
                                to="/contact"
                                className="primary_btn gray-btn-border"
                              >
                                Make Appointment
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoHeroSection;
