import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-image-lightbox/style.css";
import "./index.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";

if (window.location.hostname === "localhost") {
  axios.defaults.baseURL = "https://clinicapi.ideaclinics.com/api/v1";
} else if (window.location.hostname === "dev.ideaclinics.com") {
  // axios.defaults.baseURL = "http://localhost:2020/api/v1";
  axios.defaults.baseURL = "https://devapi.ideaclinics.com/api/v1";
} else {
  axios.defaults.baseURL = "https://clinicapi.ideaclinics.com/api/v1";
}

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
